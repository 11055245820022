@import 'styles/layers.scss';

@layer component {
  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    min-width: 530px;
  }

  .modal-body {
    height: 100%;
    padding: 22px 0 0;
  }
}
