@import 'styles/layers.scss';

@layer component {
  .line {
    column-gap: 15px;
    overflow: hidden;
    max-width: 240px;
  }

  .element {
    column-gap: 5px;
  }

  .label {
    opacity: 0.3;
  }
}
