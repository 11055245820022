@import 'styles/layers.scss';

@layer uikit {
  .fullWidth {
    width: 100%;
  }
  
  .wrap {
    border-radius: 6px;
  }
  
  .name {
    flex: 1;
    overflow-wrap: anywhere;
    font-size: 12px;
  }
  
  .left {
    flex: 1;
    margin-right: 8px;
  }
  
  .spinner {
    margin-right: 10px;
  }
  
  .content {
    width: 100%;
  }
}