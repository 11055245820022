@import 'styles/layers.scss';

@layer uikitlevel1 {
  .content {
    row-gap: 16px;
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 0.3s ease-in-out, height 0.3s ease-in-out;
    &.open {
      grid-template-rows: 1fr; 
    }
  }

  .wrap-btn {
    margin-top: 16px;
  }

  .btn {
    cursor: pointer;
    &:hover {
      .text {
        color: var(--white);
      }
    }
  }

  .text {
    color: var(--grey8);
    font-size: 12px;
  }

  .icon {
    margin-left: 10px;
    &.openicon {
      transform: rotate(180deg);
    }
  }
}