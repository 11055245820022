@import 'styles/layers.scss';

@layer component {
  .title {
    margin-bottom: 20px;
  }
  
  .wrap {
    padding: 22px;
    width: 100%;
    border-radius: 6px;
    height: 100%;
    overflow-y: auto;
  }
  
  .sub-title {
    font-size: 12px;
    margin-bottom: 20px;
    color: var(--white);
  }
  
  .content {
    flex: 1;
  }
  
  .footer {
    margin-top: 20px;
  }
}