@import 'styles/layers.scss';

@layer component {
  .content {
    margin-top: 20px;
  }

  .text {
    font-size: 12px;
    margin: 10px 0 0;
  }

  .group {
    margin-top: 20px;
    column-gap: 10px;
  }

  .btn {
    width: 101px;
    height: 41px;
  }

  .notification {
    margin-top: 20px;
  }

  .text-notification {
    margin: 0;
  }

  .link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }

  .bold {
    font-weight: 600;
  }
}