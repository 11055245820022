@import 'styles/layers.scss';

@layer uikit {
  .notification {
    margin-top: 6px;
  }

  .delete-btn {
    padding: 4px;
    border-radius: 50%;
  }
}