@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    padding: 2px;
    border-radius: 6px;
    background: transparent;
  }
  
  .dropzone {
    display: flex;
    height: 38px;
    cursor: pointer;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .dropzone {
    border: 1px dashed var(--grey4);
  }
  
  .fullWidth {
    width: 100%;
  }
  
  .error {
    .dropzone {
      border-color: var(--yellow);
    }
  }
}