@import 'styles/layers.scss';

@layer uikit {
  .file {
    width: 100%;
  }
  
  .add-file {
    color: var(--white);
    margin-left: 11px;
  }
  
  .icon {
    color: var(--white);
  }
  
  .full-width {
    width: 100%;
  }
  
  .notification {
    margin-top: 6px;
  }
  
  .uploader-core-content {
    width: 100%;
  }
  
  .label {
    color: var(--white);
    margin-bottom: 4px;
  }

  .full-progress {
    margin-bottom: 15px;
  }
}