@import 'styles/layers.scss';

@layer component {
  .title-line {
    margin-bottom: 6px;
  }

  .btn {
    font-size: 12px;
    opacity: 0.5;
    display: flex;
    align-items: center;
  }

  .preview-box {
    width: 740px;
    height: 630px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid var(--black28);
    &-error {
      border-color: var(--yellow);
    }
  }

  .input-wrap {
    border-radius: 4px;
    border: 1px solid var(--black28);
  }

  .input {
    width: 740px;
    height: 630px;
  }

  .icon {
    margin-right: 6px;
  }
}