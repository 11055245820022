@import 'styles/layers.scss';

@layer component {
  .input-wrap {
    width: 420px;
  }

  .input {
    height: 34px;
    font-size: 12px;
  }

  .input-prepend {
    padding: 0 0 0 12px;
  }

  .input-append {
    padding: 0 12px;
  }

  .icon {
    opacity: 0.6;
    &.icon-focused {
      opacity: 1;
    }
  }

  .btn {
    width: 15px;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
  }
}
