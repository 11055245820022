@import 'styles/layers.scss';

@layer uikit {
  .title {
    font-size: 22px;
    text-align: center;
    margin: 0;
  }

  .wrap {
    column-gap: 12px;
  }
}