@import 'styles/layers.scss';

@layer component {
  .title {
    font-size: 20px;
  }

  .text {
    font-size: 12px;
    margin: 30px 0;
  }

  .group-inputs {
    row-gap: 15px;
  }

  .input-box {
    column-gap: 10px;
  }

  .input-wrap {
    border-radius: 6px;
    border: 1px solid var(--black11);
  }

  .input {
    height: 42px;
    width: 359px;
  }

  .input-label {
    background-color: var(--black16);
  }

  .label {
    opacity: 0.3;
  }

  .wrap-component {
    margin-bottom: 30px;
  }

  .btn {
    padding: 10px 25px;
  }

  .btn-cancel {
    width: 101px;
  }

  .btn-pay {
    width: 111px;
  }

  .error-empty {
    height: 0;
  }

  .input-error {
    font-size: 14px;
  }
}
