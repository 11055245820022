@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    border: 1px solid var(--black11);
    height: 90px;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--black16);
  }
  
  .phrase {
    flex: 1;
    margin-right: 8px;
    font-size: 12px;
    line-height: 160%;
  }
  
  .btn-generate {
    width: 25px;
    height: 25px;
    padding: 0;
  }
  
  .btn-copy {
    width: 25px;
    height: 25px;
    padding: 0;
  }
}