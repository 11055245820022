@import 'styles/layers.scss';

@layer component {
  .btns {
    margin-top: 40px;
    column-gap: 20px;
  }

  .btn {
    width: 140px;
    height: 41px;
  }

  .dialog {
    min-width: 455px;
  }

  .body {
    padding: 22px;
  }
}