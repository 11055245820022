@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    background-color: transparent;
    padding: 25px 0 0 20px;
    flex: 1;
    column-gap: 20px;
  }
  
  .all {
    border-radius: 4px;
  }
  
  .bottom {
    border-bottom: 4px;
  }
}