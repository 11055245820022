@import 'styles/layers.scss';

@layer component {
  .wrap {
    width: var(--max-width);
  }

  .spacer {
    height: 5px;
    width: 100%;
  }

  .content {
    padding-top: 25px;
    width: 100%;
    flex: 1;
  }
  
  @supports (scrollbar-gutter: stable) {
    .content {
      scrollbar-gutter: stable;
    }
  }
  
  .wrap-content {
    width: 100%;
  }
  
  .tab-item {
    display: flex;
    align-items: center;
    height: 42px;
    margin: 0;
  }
  
  .value {
    color: var(--orange1);
  }
  
  .icon {
    color: var(--orange1);
    margin-left: 6px;
  }
  
  .dropdown-toggle {
    background-color: transparent;
  }
  
  .dropdown-menu {
    padding: 0;
    background-color: var(--black14);
    &:hover {
      background: var(--black14);
    }
    right: 0;
  }
  
  .menu {
    height: 42px;
    margin-bottom: 21px;
  }
  
  .divider {
    margin: 54px 0 31px;
  }
}