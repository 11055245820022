@import 'styles/layers.scss';

@layer uikit {
  .full-width {
    width: 100%;
  }

  .title {
    margin-bottom: 6px;
  }

  .info {
    font-size: 12px;
  }
}