@import 'styles/layers.scss';

@layer component {
  .mrb {
    margin-bottom: 20px;
  }

  .wrap {
    padding: 22px;
    width: 100%;
    border-radius: 6px;
    height: 100%;
    overflow-y: auto;
  }
  
  .title-wrap {
    margin-bottom: 30px;
  }
  
  .info {
    font-size: 12px;
  }
  
  .content {
    flex: 1;
  }
  
  .footer {
    margin-top: 20px;
  }

  .text {
    margin: 0;
  }

  .link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }

  .btn-try-again {
    width: 117px;
    height: 41px;
  }
}