@import 'styles/layers.scss';

@layer uikit {
  .item {
    width: 100%;
  }
  
  .title {
    flex: 1;
    overflow-wrap: anywhere;
    margin-right: 8px;
    font-size: 12px;
  }
  
  .full-width {
    width: 100%;
  }
}