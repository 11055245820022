@import 'styles/layers.scss';

@layer uikit {
  .phrase {
    float: right;
    padding: 12px;
    border-radius: 4px;
  }
  
  .agreement {
    margin-top: 8px;
  }
  
  .modeswitcher {
    margin-bottom: 16px;
  }
  
  .mode {
    margin-left: 20px;
    font-size: 16px;
    cursor: pointer;
    &-active {
      opacity: 1;
      color: var(--blue3);
    }
    &-zero {
      margin-left: 0;
    }
  }
  
  .input {
    height: 86px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 160%;
  }
  
  .light {
    color: var(--black);
  }
  
  .dark {
    color: var(--white);
  }
}