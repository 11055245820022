@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    display: flex;
    flex-direction: column;
  }
  
  .prepend {
    height: 100%;
    padding: 0 4px 0 12px;
    align-items: center;
  }
  
  .append {
    height: 100%;
    padding: 0 12px 0 14px;
    align-items: center;
  }
  
  .input-wrap {
    border-radius: 6px;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    position: relative;
    background-color: transparent;
    border: 1px solid var(--black28);
    &_focused {
      border: 1px solid var(--white2);
      .input {
        color: var(--white);
      }
    }
    &:hover:not(.input-wrap_focused) {
      background-color: var(--black27);
    }
  }
  
  .description {
    font-size: 12px;
    opacity: 0.3;
    margin-bottom: 10px;
  }
  
  .input {
    flex: 1;
    width: 100%;
    height: 37px;
    padding: 6px;
    border-radius: 6px;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    color: var(--white);
    outline: none;
    border: none;
  }
  
  .padding {
    padding: 10px 12px 11px;
  }
  
  .textarea {
    height: auto;
    resize: none;
    cursor: auto;
  }
  
  .label {
    display: flex;
    margin-bottom: .5rem;
    white-space: nowrap;
  }
  
  .error-empty {
    height: 20px;
  }
  
  .error {
    margin-top: 8px;
  }
  
  .tooltip {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin: 0 10px 0 0;
  }
  
  .loading {
    border-radius: 4px;
  }
  
  .disabled {
    cursor: not-allowed;
  }  

  .notification-red {
    .input-wrap {
      &_invalid {
        background-color: var(--red2);
        border-color: var(--red);
        .input {
          color: var(--white);
        }
      }
    }
  }

  .notification-yellow {
    .input-wrap {
      &_invalid {
        background-color: var(--black16);
        border-color: var(--yellow);
        .input {
          color: var(--white);
        }
      }
    }
  }

  .full-width {
    width: 100%;
  }
}