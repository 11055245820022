@import 'styles/layers.scss';

@layer component {
  .title-wrap {
    margin-bottom: 30px;
  }

  .title {
    font-size: 20px;
  }

  .wrap-component {
    margin-bottom: 30px;
  }

  .btn-cancel {
    margin-right: 10px;
    width: 86px;
  }

  .btn-confirm {
    width: 94px;
  }

  .input-error {
    font-size: 14px;
  }

  .input-wrap {
    border-radius: 6px;
    width: 100%;
  }

  .input {
    height: 90px;
    font-size: 12px;
    width: 100%;
  }

  .input-label {
    background-color: var(--black16);
  }

  .error-empty {
    height: 0;
  }

  .btn {
    padding: 10px 55px;
  }
}