@import 'styles/layers.scss';

@layer uikit {
  .content {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 9px;
    border: 1px solid var(--white);
  }
  
  .link {
    color: inherit;
    text-decoration: none;
    &:hover, &:active {
      color: inherit;
    }
  }
}
