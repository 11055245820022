@import 'styles/layers.scss';

@layer uikit {
  .item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  
  .fullWidth {
    width: 100%;
  }
}